// Generated by Framer (b0f2619)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useActiveVariantCallback, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["roE13UwAN", "W8FmFUSFf", "SWpMlpSNc", "tPcVLiudT", "jpirFLQa6", "Cwyb5NlxY"];

const serializationHash = "framer-quog9"

const variantClassNames = {Cwyb5NlxY: "framer-v-1psj75s", jpirFLQa6: "framer-v-4sxncp", roE13UwAN: "framer-v-8fsqi3", SWpMlpSNc: "framer-v-fbcgij", tPcVLiudT: "framer-v-185a6er", W8FmFUSFf: "framer-v-1q7l3fw"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {"Image 1:1": "Cwyb5NlxY", "Image 16:9": "tPcVLiudT", "Image 3:2": "SWpMlpSNc", "Image 4:3": "W8FmFUSFf", "Image 4:5": "jpirFLQa6", Default: "roE13UwAN"}

const getProps = ({height, id, image, mouseEnter, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "roE13UwAN", vhrLqezAm: mouseEnter ?? props.vhrLqezAm, WKR34BYYJ: image ?? props.WKR34BYYJ} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;image?: {src: string; srcSet?: string};mouseEnter?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, WKR34BYYJ, vhrLqezAm, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "roE13UwAN", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onMouseEnterslyzpm = activeVariantCallback(async (...args) => {
if (vhrLqezAm) {
const res = await vhrLqezAm(...args);
if (res === false) return false;
}
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Transition value={transition}><Image {...restProps} animate={variants} background={{alt: "", fit: "fill", sizes: "min(256px, 100vw)", ...toResponsiveImage(WKR34BYYJ)}} className={cx(serializationHash, ...sharedStyleClassNames, "framer-8fsqi3", className, classNames)} data-framer-name={"Default"} data-highlight initial={variant} layoutDependency={layoutDependency} layoutId={"roE13UwAN"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onMouseEnter={onMouseEnterslyzpm} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({Cwyb5NlxY: {"data-framer-name": "Image 1:1"}, jpirFLQa6: {"data-framer-name": "Image 4:5"}, SWpMlpSNc: {"data-framer-name": "Image 3:2", background: {alt: "", fit: "fill", sizes: "min(255.501px, 100vw)", ...toResponsiveImage(WKR34BYYJ)}}, tPcVLiudT: {"data-framer-name": "Image 16:9"}, W8FmFUSFf: {"data-framer-name": "Image 4:3"}}, baseVariant, gestureVariant)}/></Transition>
</LayoutGroup>)

});

const css = [".framer-quog9[data-border=\"true\"]::after, .framer-quog9 [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-quog9.framer-dt5hfl, .framer-quog9 .framer-dt5hfl { display: block; }", ".framer-quog9.framer-8fsqi3 { height: 256px; overflow: hidden; position: relative; width: 256px; }", ".framer-quog9.framer-v-1q7l3fw.framer-8fsqi3 { aspect-ratio: 1.3333333333333333 / 1; height: var(--framer-aspect-ratio-supported, 192px); }", ".framer-quog9.framer-v-fbcgij.framer-8fsqi3 { aspect-ratio: 1.4970760233918128 / 1; height: var(--framer-aspect-ratio-supported, 171px); }", ".framer-quog9.framer-v-185a6er.framer-8fsqi3 { aspect-ratio: 1.7777777777777777 / 1; height: var(--framer-aspect-ratio-supported, 144px); }", ".framer-quog9.framer-v-4sxncp.framer-8fsqi3 { aspect-ratio: 0.8 / 1; height: var(--framer-aspect-ratio-supported, 320px); }", ".framer-quog9.framer-v-1psj75s.framer-8fsqi3 { aspect-ratio: 1 / 1; height: var(--framer-aspect-ratio-supported, 256px); }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 200
 * @framerIntrinsicWidth 200
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"W8FmFUSFf":{"layout":["fixed","fixed"]},"SWpMlpSNc":{"layout":["fixed","fixed"]},"tPcVLiudT":{"layout":["fixed","fixed"]},"jpirFLQa6":{"layout":["fixed","fixed"]},"Cwyb5NlxY":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"WKR34BYYJ":"image","vhrLqezAm":"mouseEnter"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 */
const Framerm9wBfG4JS: React.ComponentType<Props> = withCSS(Component, css, "framer-quog9") as typeof Component;
export default Framerm9wBfG4JS;

Framerm9wBfG4JS.displayName = "Image Copy";

Framerm9wBfG4JS.defaultProps = {height: 200, width: 200};

addPropertyControls(Framerm9wBfG4JS, {variant: {options: ["roE13UwAN", "W8FmFUSFf", "SWpMlpSNc", "tPcVLiudT", "jpirFLQa6", "Cwyb5NlxY"], optionTitles: ["Default", "Image 4:3", "Image 3:2", "Image 16:9", "Image 4:5", "Image 1:1"], title: "Variant", type: ControlType.Enum}, WKR34BYYJ: {title: "Image", type: ControlType.ResponsiveImage}, vhrLqezAm: {title: "Mouse Enter", type: ControlType.EventHandler}} as any)

addFonts(Framerm9wBfG4JS, [])